// import Home from '../components/Home';
import dynamic from 'next/dynamic';

const Home = dynamic(()=> import('@/components/Home'))
import Layout from '@/components/Layout';
// const Layout = dynamic(()=> import('@/components/Layout'))
Home.getLayout = function getLayout(page) {
    return (
        <Layout webViewMobile={true} web='home' >{page}</Layout>
    );
};

export default Home;


